import { Disclosure } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function CustomNavBar() {

  const [search, setSearch] = useState('')

  const today = new Date()
  const todayFormatted = today.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  
  const startDate = new Date(today.getFullYear(), 0, 1);
  const days = Math.floor((today - startDate) /
      (24 * 60 * 60 * 1000));
   
  const weekNumber = Math.ceil(days / 7);


  const handleChange = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()  
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(search)}`;
    window.open(searchUrl, "_blank");
  }


  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <img className="block h-8 w-auto lg:hidden"
                    src="/images/simacek-s.png"
                    alt="SIM.portal"
                  />
                  <img className="hidden h-8 w-auto lg:block"
                    src="/images/simacek-s.png"
                    alt="SIM.portal"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    
                    <span to="/" className='btn btn-muted nav-inactive hover:bg-gray-900 transition-all'>
                      app.simacek.at
                    </span>

                    {/* <NavLink to="/news" className={({isActive, isPending}) => isPending ? 'pending' : isActive ? 'nav-active btn btn-muted' : 'btn btn-muted nav-inactive'}>
                      News
                    </NavLink> */}
                    
                    {/* <NavLink to="/kontakte" className={({isActive, isPending}) => isPending ? 'pending' : isActive ? 'nav-active btn btn-muted' : 'btn btn-muted nav-inactive'}>
                      Kontakte
                    </NavLink> */}


                  </div>
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    <span className="nav-inactive">
                      {todayFormatted} (KW {weekNumber})
                    </span>
                  </div>
                </div>

              </div>
              <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Google Suche + Enter
                  </label>
                  
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <form onSubmit={handleSubmit}>
                      <input
                        id="search"
                        name="search"
                        className="header-input"
                        placeholder="Google Suche + Enter"
                        type="search"
                        value={search}
                        autoFocus
                        
                        onChange={handleChange}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden">
                <Disclosure.Button className="disclosed-menu-btn">
                  <span className="sr-only">Hauptmenu öffnen</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <Disclosure.Button as="a" href="/" className="disclosed-btn mx-5">
                app.simacek.at
              </Disclosure.Button>
              {/* 
              <Disclosure.Button as="a" href="/news" className="disclosed-btn mx-5">
                News
              </Disclosure.Button> */}

              {/* <Disclosure.Button as="a" href="/kontakte" className="disclosed-btn mx-5">
                Kontakte
              </Disclosure.Button> */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}