import { useEffect } from "react";
import CustomNavBar from "./components/NavBar";
import { BanknotesIcon, ClockIcon, ArrowDownTrayIcon, NewspaperIcon, FaceSmileIcon, LanguageIcon } from '@heroicons/react/20/solid'

const iOSUrl = 'https://apps.apple.com/us/app/sim-app/id6473154777'
// const androidUrl = 'https://play.google.com/store/apps/details?id=host'


const features = [
  {
    name: 'Newsfeed',
    description:
      'Immer auf dem neuesten Stand mit unserem Newsfeed: Entdecken Sie spannende Einblicke und Updates direkt aus dem SIMACEK Portal.',
    icon: NewspaperIcon,
  },
  {
    name: 'Mitarbeiter Benefits',
    description:
      'Entdecken Sie Mitarbeiter-Benefits, die begeistern: Sichern Sie sich Einkaufsgutscheine und nehmen Sie an exklusiven Aktionen teil – ein zentraler Bestandteil unseres Employer Brandings.',
    icon: FaceSmileIcon,
  },
  {
    name: 'Lohnzettel',
    description:
      'Verwalten Sie Ihre Lohnzettel effizient: Anzeigen, Speichern im PDF-Format oder Ausdrucken – alles an einem Ort.',
    icon: BanknotesIcon,
  },
  {
    name: 'Zeiterfassung *',
    description: 'Einfach und schnell via QR-Code oder NFC – für eine präzise und unkomplizierte Arbeitszeiterfassung.',
    icon: ClockIcon,
    annotation: '* Dieses Modul wird nach dem GoLive der simacek.tools Plattform freigegeben und in Phasen für eine optimierte Integration und Nutzung schrittweise ausgerollt.'
  },
  {
    name: 'Mehrsprachigkeit',
    description: 'Mit Mehrsprachigkeit in Deutsch, Englisch, Serbo-Kroatisch und Türkisch bringt die SIMACEK.app Teamkommunikation und Benutzerfreundlichkeit auf ein neues Level.',
    icon: LanguageIcon,
  },
]

function App() {


  useEffect(() => {
    document.title = "app.simacek.at"
  }, [])


  return (
    <div className="app">
      <CustomNavBar />
      <div className="flex min-h-full flex-col max-w-6xl mx-auto shadow-md">
        <div className="overflow-hidden bg-white pt-16 sm:pt-18">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="px-8 py-4">
              <div className="lg:max-w-lg mb-10 pb-10">
                <h2 className="text-base font-semibold leading-7 text-sky-600">Simacek Mitarbeiter App</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  sim.app <sup className="bg-gray-800 text-white text-sm py-1.5 px-3 font-mono">v 1.0.3</sup>
                </p>
                <p className="mt-6 text-lg leading-8 text-medium text-gray-600">
                Mit dieser App haben Dienstnehmer alles Wichtige stets griffbereit: 
                Von der nahtlosen Zeiterfassung bis hin zum einfachen Zugriff auf Lohnzettel. 
                
                </p>
                
                <div className="download mt-10">
                  <a
                    href={iOSUrl}
                    target="_blank"
                    rel="noreferrer"
                    type="button"
                    className="m-2 text-md inline-flex items-center gap-x-2 bg-white-600 px-3.5 py-2.5 font-semibold bg-gray-800 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Apple App Store
                  </a>


                  <p className="m-2 text-sm">
                    Download für Android folgt in Kürze.
                  </p>

                </div>

                <h2 className="mt-10 text-2xl">Vorteile auf einem Blick</h2>
                <dl className="mt-5 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9 ">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-sky-600" aria-hidden="true" />
                        {feature.name}<br />
                        
                      </dt>{' '}
                      <dd className="inline">
                        {feature.description}
                        {feature.annotation && <span className="block text-sm leading-4 mt-2 text-green-700 bg-green-200 border-1 border-green-300 p-3">{feature.annotation}</span>}  
                      </dd>
                    </div>
                  ))}
                </dl>

              
                
              </div>
            </div>

            
            <div>
            {/* <img
              src="/images/qr-code.png"
              alt="Product screenshot"
              className="mx-auto mb-10 md:block hidden border border-spacing-3 shadow-sm mt-10"
              width={250}
              height={250}
            /> */}
            <img
              src="/images/hero/simacek-app.png"
              alt="Product screenshot"
              className="max-w-none md:-ml-2 lg:-ml-0 sticky"
              width={550}
            />
            </div>
          </div>

          
        </div>
      </div>
      </div>
    </div>
  );
}

export default App;
